// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Oa6EMIEFOfeoyR_NXSx5{box-sizing:border-box}.tMHIkt_7cFcDSQIePsrZ{border-radius:50%;width:1em;height:1em}", "",{"version":3,"sources":["webpack://./src/components/Stats/FirstStatsDisplay-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CAGD,sBACC,iBAAA,CACA,SAAA,CACA,UAAA","sourcesContent":[".chartContainer {\n\t\tbox-sizing: border-box;\n\t}\n\n\t.colorBlip {\n\t\tborder-radius: 50%;\n\t\twidth: 1em;\n\t\theight: 1em;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": "Oa6EMIEFOfeoyR_NXSx5",
	"colorBlip": "tMHIkt_7cFcDSQIePsrZ"
};
export default ___CSS_LOADER_EXPORT___;
