// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RQQwFUO5V2eZ8R_WjkcN{min-width:6.5rem}.EKrG4occtg6sPIHuhQ6Z{min-width:3.5rem}.wakb98Ng7LDD0SAMFCla{display:flex;flex-direction:row;align-items:center;justify-content:center}.IPvswYL7u6mhUBA6GCsg{flex-grow:0;flex-basis:auto;display:inline-block;min-width:auto;max-width:100%;line-height:initial;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/components/Match-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGD,sBACC,gBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CAGD,sBACC,WAAA,CACA,eAAA,CACA,oBAAA,CACA,cAAA,CACA,cAAA,CACA,mBAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".teamScoreWide {\n\t\tmin-width: 6.5rem;\n\t}\n\n\t.teamScore {\n\t\tmin-width: 3.5rem;\n\t}\n\n\t.nameContainer {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t}\n\n\t.teamName {\n\t\tflex-grow: 0;\n\t\tflex-basis: auto;\n\t\tdisplay: inline-block;\n\t\tmin-width: auto;\n\t\tmax-width: 100%;\n\t\tline-height: initial;\n\t\toverflow: hidden;\n\t\twhite-space: nowrap;\n\t\ttext-overflow: ellipsis;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamScoreWide": "RQQwFUO5V2eZ8R_WjkcN",
	"teamScore": "EKrG4occtg6sPIHuhQ6Z",
	"nameContainer": "wakb98Ng7LDD0SAMFCla",
	"teamName": "IPvswYL7u6mhUBA6GCsg"
};
export default ___CSS_LOADER_EXPORT___;
