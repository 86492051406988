

export enum StyleGrade {
	None,
	D,
	C,
	B,
	A,
	S,
	SS,
	SSS
}
