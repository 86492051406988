// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e4K_Mai5YAraO6lKw4fQ{background-color:#000;flex-wrap:wrap;display:flex}.e4K_Mai5YAraO6lKw4fQ>img{width:48px}", "",{"version":3,"sources":["webpack://./src/components/ContestSummary-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,cAAA,CACA,YAAA,CACA,0BACC,UAAA","sourcesContent":[".gachaPreview {\n\t\tbackground-color: black;\n\t\tflex-wrap: wrap;\n\t\tdisplay: flex;\n\t\t> img {\n\t\t\twidth: 48px;\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gachaPreview": "e4K_Mai5YAraO6lKw4fQ"
};
export default ___CSS_LOADER_EXPORT___;
