// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rVwtpsPBrtkLxr1_BKsv:not(:last-child){border-bottom:solid #fff 1px}", "",{"version":3,"sources":["webpack://./src/components/YakumanDisplay-styles.module.scss"],"names":[],"mappings":"AACE,uCACC,4BAAA","sourcesContent":[".yakumanEntry {\n\t\t&:not(:last-child) {\n\t\t\tborder-bottom: solid white 1px;\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"yakumanEntry": "rVwtpsPBrtkLxr1_BKsv"
};
export default ___CSS_LOADER_EXPORT___;
