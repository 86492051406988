// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pe77HsUwtbKD3MUfWjWY{color:#fff;background-color:#a51563}.CaSDH2o6lTFDGrwINLxS{color:#fff;background-color:#cc7c19}.gjsqO2cm7AaeYpD3XlAl{color:#fff;background-color:#175a82}.OkMS8vkwUfYF55BypNPE{color:#fff;background-color:#527d00}.Dkik_mZb2gvOp_pfxi1g{opacity:.5}", "",{"version":3,"sources":["webpack://./src/components/GameResultSummary-localStyles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,wBAAA,CAGD,sBACC,UAAA,CACA,wBAAA,CAGD,sBACC,UAAA,CACA,wBAAA,CAGD,sBACC,UAAA,CACA,wBAAA,CAGD,sBACC,UAAA","sourcesContent":[".seat-0 {\n\t\tcolor: white;\n\t\tbackground-color: #A51563;\n\t}\n\n\t.seat-1 {\n\t\tcolor: white;\n\t\tbackground-color: #CC7C19;\n\t}\n\n\t.seat-2 {\n\t\tcolor: white;\n\t\tbackground-color: #175A82;\n\t}\n\n\t.seat-3 {\n\t\tcolor: white;\n\t\tbackground-color: #527D00;\n\t}\n\n\t.hidden {\n\t\topacity: 0.5;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"seat-0": "pe77HsUwtbKD3MUfWjWY",
	"seat-1": "CaSDH2o6lTFDGrwINLxS",
	"seat-2": "gjsqO2cm7AaeYpD3XlAl",
	"seat-3": "OkMS8vkwUfYF55BypNPE",
	"hidden": "Dkik_mZb2gvOp_pfxi1g"
};
export default ___CSS_LOADER_EXPORT___;
