// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zhhDYGAZUANCiOBnLAzc{display:flex;position:relative;justify-content:center;align-items:center;height:40px;width:40px}.Yj0qIJmufcG4qFMAxmlc{max-width:100%;max-height:100%}", "",{"version":3,"sources":["webpack://./src/components/TeamImage-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,WAAA,CACA,UAAA,CAGD,sBACC,cAAA,CACA,eAAA","sourcesContent":[".container {\n\t\tdisplay: flex;\n\t\tposition: relative;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\n\t\theight: 40px;\n\t\twidth: 40px;\n\t}\n\n\t.image {\n\t\tmax-width: 100%;\n\t\tmax-height: 100%;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "zhhDYGAZUANCiOBnLAzc",
	"image": "Yj0qIJmufcG4qFMAxmlc"
};
export default ___CSS_LOADER_EXPORT___;
